<template lang="html">
  <div class="container-fluid contain">
    <div>
      <div class="">
        <img src="@/assets/images/icon_arrow_left.png" style="width: 18px" @click="$router.go(-1)"/>
        <div class="login-title" style="margin-top: 32px; margin-bottom: 10px; margin-left: 25px; font-size: 30px; text-align: left;">Contact Support</div>
        <div class="field-name" style="flex-direction: column; margin: 35px 25px 0 25px">
          <div>NAME</div>
          <input class="field-area" style="width: 100%;" v-model="name">
        </div>
        <div class="field-name" style="flex-direction: column; margin: 35px 25px 0 25px">
          <div>SUBJECT</div>
          <input class="field-area" style="width: 100%;" v-model="subject">
        </div>
        <div class="field-name" style="flex-direction: column; margin: 35px 25px 90px 25px">
          <div>MESSAGE</div>
          <textarea class="field-area" style="width: 100%;" v-model="message" rows="4"></textarea>
        </div>
        <button class="btn btn-login d-flex justify-content-center align-items-center"
                style="width: 98%;" type="button" @click="onPressSend">
          <div class="loader" v-if="state.isLoading"></div>
          <div v-else>SEND</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import {reactive, toRefs} from '@vue/composition-api';

  export default {
    setup(props, {root}) {
      const data = reactive({
        name: 'Famous Jerk Flex',
        subject: null,
        message: null
      });

      const onPressSend = async () => {
        if (!root.state.currentUser?.id) {
          root.actions.alert.showError({message: 'You mush login first!'});
          return;
        }
        await root.actions.startLoading();
        try {
          const params = {userId: root.state.currentUser?.id, subject: data.subject, message: data.message}
          await root.actions.user.requestSupport(params);
          root.actions.alert.showSuccess({message: 'Your message was sent successfully!'});
          root.$router.go(-1);
        } catch (e) {
          console.log(e)
        } finally {
          await root.actions.finishLoading();
        }
      };

      return {
        onPressSend,
        ...toRefs(data)
      }
    },
  }
</script>

<style lang="css" scoped>
  textarea:hover {
    outline-width: 0 !important;
  }

  textarea {
    outline-width: 0 !important;
  }

  .field-name {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
  }

  .field-area {
    display: flex;
    border-bottom-width: 1px !important;
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-top-width: 0 !important;
    border-style: solid !important;
    border-color: black !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 8px;
    padding-left: 2px;
    padding-right: 2px;
  }

  input.field-area:focus {
    outline-width: 0 !important;
  }
</style>
